import React from 'react';

const Home = () => {
    return (
        <div>
            <div className="welcome">
                <h1>Welcome to Haunting Enigmas</h1>
            </div>
            <div className="about-us">
                <h2>Hello Listeners,</h2>
                <br></br>
                <p>Welcome to our podcast "Haunting Enigmas". Hosted by Chyna and Ashley.</p>
                <p>We are two best friends who love all things spooky. We want to invite you to join us,</p>
                <p>in our podcast by writing in your personal creepy stories.</p>
                <br></br>
                <p>It will be up to you to try and spook us with a frightening fact, or</p>
                <p>doop us with a freaky fiction. We will read your story and guess if it is fact or fiction.</p>
                <p>then the answer will be revealed after our final consensus.</p>

            </div>
            <div className="spotify-embed">
                <iframe
                    style={{borderRadius: "12px"}}
                    src="https://open.spotify.com/embed/episode/4WnHuaybN8i899rwoNUm2M?utm_source=generator"
                    width="100%"
                    height="352"
                    frameBorder=""
                    allowFullScreen=""
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    loading="lazy"
                >
                </iframe>
            </div>
        </div>
    );
};

export default Home;