import React, { useEffect, useState , Fragment} from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { authorize } from './auth/authentication';
import atob from 'atob';
import './App.css';
import logoImage from './assets/HauntingEnigmasLogo.png';
import bannerImage from './assets/banner.jpg';
import SubmitYourStory from './pages/SubmitStory';
import ListenerPortal from './pages/ListenerPortal';
import Successful from './pages/Successful';
import AdminStory from './pages/AdminStory';
import Home from './pages/Home';
import Admin from './pages/Admin';
import EditUser from './pages/EditUser';
import NotFound from './pages/NotFound'; // Import NotFound component
import { trySilentLogin } from './auth/AuthUtils';
import InstagramIcon from './assets/instagram.png';
import TikTokIcon from './assets/tik-tok.png';
import SpotifyIcon from './assets/spotify.png';
import ReactGA from 'react-ga';


function App() {

  useEffect(() => {
    ReactGA.initialize('G-6PL9X5C536');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const [userEmail, setUserEmail] = useState(null);
  const [userGroups, setUserGroups] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      decodeIdTokenAndSetStates();
    }
  }, [isAuthenticated]);




  const decodeIdTokenAndSetStates = () => {
    const idToken = localStorage.getItem('id_token');
    if (idToken) {
      const payload = JSON.parse(atob(idToken.split('.')[1]));
      console.log('Decoded Payload:', payload); // Confirm payload structure

      const groups = payload['cognito:groups'] || [];
      console.log('Groups:', groups); // This should log the groups

      setUserEmail(payload.email);
      setUserGroups(groups);

      localStorage.setItem('email', payload.email);
      localStorage.setItem('groups', JSON.stringify(groups));
    } else {
      console.log('No ID token found in localStorage.');
    }
  };


  useEffect(() => {
    trySilentLogin(setUserEmail);
    decodeIdTokenAndSetStates();
  }, []);

  const handleLoginClick = async () => {
    try {
      // Initiate the login process
      const response = await authorize(); // Assuming authorize is an async function that handles authentication
      // Assuming the response or some logic here can determine if the login was successful
      if (response.success) {
        setIsAuthenticated(true); // Set authentication state to true
      }
    } catch (error) {
      console.error("Failed to log in:", error);
    }
  };

  const isAdmin = userGroups.includes('a3a39454-4f83-4dcc-8e35-ed70ca94198d');

  return (
      <Router>
        <div className="App">
          <div className="top-bar">
            <Link to="/">
              <img src={logoImage} alt="Logo" className="logo" />
            </Link>
            <div className="login-info">
              {userEmail ? `${userEmail}` : <button className="login-button" onClick={handleLoginClick}>Login</button>}
            </div>
          </div>
          <div className="navigation-container">
            <div className="page-buttons">
              <Link to="/submit-your-story">Submit Your Story <i className="fi fi-rs-book-spells" style={{ color: 'white' }}></i></Link>
              {userEmail && <Link to="/listener-portal">Listener Portal <i className="fi fi-rs-hat-wizard" style={{ color: 'white' }}></i></Link>}
              {isAdmin && <Link to="/admin">Admin <i className="fi fi-rs-crystal-ball" style={{ color: 'white' }}></i></Link>}
            </div>
          </div>
          <div className="banner-container">
            <img src={bannerImage} alt="Coming Soon" className="banner-image" />
          </div>
          <Routes>
            <Route index element={<Navigate to="/home" />} />
            <Route path="/home" element={<Home />} />
            <Route path="/submit-your-story" element={<SubmitYourStory />} />
            <Route path="/successful" element={<Successful />} />
            <Route path="/listener-portal" element={<ListenerPortal />} />
            <Route path="/AdminStory" element={<AdminStory />} />
            <Route path="/EditUser" element={<EditUser />} />
            {isAdmin && (
                <Fragment>
                  <Route path="/admin" element={<Admin />} />
                  <Route path="/AdminStory" element={<AdminStory />} />
                </Fragment>
            )}
            <Route path="*" element={<NotFound />} />
          </Routes>
          <div className={"social-text"}>
            <p> Please follow us on social media: </p>
          </div>
          <div className="social-links">
            <a href="https://instagram.com/haunting_enigmas" target="_blank" rel="noopener noreferrer">
              <img src={InstagramIcon} alt="Instagram" className="social-icon" />
            </a>
            <a href="https://tiktok.com/@haunting_enigmas" target="_blank" rel="noopener noreferrer">
              <img src={TikTokIcon} alt="TikTok" className="social-icon" />
            </a>
            <a href="https://open.spotify.com/show/54wjeNdBCwHeOWczBOaa5B?si=601b78b8c5a840f0" target="_blank" rel="noopener noreferrer">
              <img src={SpotifyIcon} alt="Spotify" className="social-icon" />
            </a>
          </div>
          <div className="new-section">
            <a href="https://www.flaticon.com/free-icons/spotify-sketch" title="spotify sketch icons">Spotify sketch icons created by Fathema Khanom - Flaticon</a>
            <a href="https://www.flaticon.com/free-icons/tiktok" title="tiktok icons">Tiktok icons created by Freepik - Flaticon</a>
            <a href="https://www.flaticon.com/free-icons/instagram-logo" title="instagram-logo icons">Instagram-logo icons created by Pixel perfect - Flaticon</a>
          </div>
        </div>
      </Router>
  );
}

export default App;
