import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { trySilentLogin } from '../auth/AuthUtils';
import { apiEndpoint } from '../config';

const UpdateUserDetails = () => {
    const [alias, setAlias] = useState('');
    const [pronouns, setPronouns] = useState('');
    const [name, setName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [userEmail, setUserEmail] = useState(null);

    useEffect(() => {
        trySilentLogin(setUserEmail);
    }, []);

    const handleSubmit = async () => {
        if ([alias, pronouns, name].some(field => field.trim() === '')) {
            alert("All fields are required!");
            return;
        }

        setIsLoading(true);
        const idToken = localStorage.getItem('id_token');
        if (!idToken) {
            console.error('Token is not available');
            setIsLoading(false);
            return;
        }

        try {
            const response = await axios({
                url: `${apiEndpoint}userDetailUpdate`,
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                data: JSON.stringify({
                    alias: alias,
                    pronouns: pronouns,
                    name: name,
                    email: userEmail, // Using userEmail from state
                }),
            });

            if (response.status === 200) {
                alert("Details updated successfully");
                navigate('/listener-portal'); // Navigating to ListenerPortal
            }
        } catch (error) {
            console.error('API Error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const inputStyle = {
        padding: '10px',
        fontSize: '16px',
        width: '100%',
        marginBottom: '20px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    };

    return (
        <div>
            <div className="submit-title">
                <h2>Update Your Details</h2>
            </div>
            <div className="input-container">
                <label htmlFor="alias">Alias:</label>
                <input type="text" id="alias" value={alias} onChange={(e) => setAlias(e.target.value)} style={inputStyle} />

                <label htmlFor="pronouns">Pronouns:</label>
                <input type="text" id="pronouns" value={pronouns} onChange={(e) => setPronouns(e.target.value)} style={inputStyle} />

                <label htmlFor="name">Name:</label>
                <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} style={inputStyle} />

                <button className="submit-button" onClick={handleSubmit} disabled={isLoading}>
                    {isLoading ? (
                        <>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </>
                    ) : (
                        'Update'
                    )}
                </button>
            </div>
        </div>
    );
};

export default UpdateUserDetails;

