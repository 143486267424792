import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

    const goHome = () => {
        navigate('/Home');
    };

    return (
        <div className={"not-found"}>
            <h1>404: Page Not Found</h1>
            <p>Sorry, the page you are looking for does not exist.</p>
            <button className="login-button" onClick={goHome}>Go Home</button>
        </div>
    );
};

export default NotFound;