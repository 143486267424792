let redirectUri;
if (window.location.hostname === 'localhost') {
    redirectUri = 'http://localhost:3000/Home';
} else {
    redirectUri = 'https://react.hauntingenigmas.com/Home';
}

export const clientId = '38lat7l7o1s64nl5h8ckk9a7e1';
export { redirectUri }; // Exported for use elsewhere
export const authUrl = 'https://hauntingenigmas-stories.auth.us-east-1.amazoncognito.com/authorize';
export const tokenUrl = 'https://hauntingenigmas-stories.auth.us-east-1.amazoncognito.com/token';
export const apiEndpoint = 'https://api.hauntingenigmas.com/prod/';
