import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { trySilentLogin } from '../auth/AuthUtils';
import { apiEndpoint } from '../config';
import { authorize } from '../auth/authentication';

const SubmitYourStory = () => {
    const [alias, setAlias] = useState('');
    const [pronoun, setPronoun] = useState('');
    const [who, setWho] = useState('');
    const [subject, setSubject] = useState('');
    const [authenticity, setAuthenticity] = useState('');
    const [story, setStory] = useState(localStorage.getItem('story') || '');
    const [isLoading, setIsLoading] = useState(false);
    const [userEmail, setUserEmail] = useState(null);
    const navigate = useNavigate();
    const [customAlias, setCustomAlias] = useState(null);
    const [customPronouns, setCustomPronouns] = useState(null);

    useEffect(() => {
        trySilentLogin(setUserEmail, null, setCustomAlias, setCustomPronouns);
    }, []);

    useEffect(() => {
        // Replacing API call with values from memory
        const storedAlias = localStorage.getItem('custom_Alias');
        const storedPronoun = localStorage.getItem('custom_Pronouns');

        setAlias(storedAlias || '');
        setPronoun(storedPronoun || '');
    }, [userEmail]);

    const handleStoryChange = (e) => {
        const newStory = e.target.value;
        setStory(newStory);
        localStorage.setItem('story', newStory);
    };

    const handleSubmit = async () => {
        if ([alias, pronoun, who, subject, authenticity, story].some(field => field.trim() === '')) {
            alert("All fields are required!");
            return;
        }

        setIsLoading(true);
        const idToken = localStorage.getItem('id_token');
        if (!idToken) {
            console.error('Token is not available');
            setIsLoading(false);
            return;
        }

        try {
            const response = await axios({
                url: `${apiEndpoint}userStoryPost`,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                data: JSON.stringify({
                    story: story,
                    alias: alias,
                    authenticity: authenticity,
                    pronoun: pronoun,
                    subject: subject,
                    who: who,
                }),
            });

            if (response.status === 200) {
                localStorage.removeItem('story');
                navigate('/Successful');
            }
        } catch (error) {
            console.error('API Error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const inputStyle = {
        padding: '10px',
        fontSize: '16px',
        width: '100%',
        marginBottom: '20px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    };

    const requiredFieldStyle = (value) => {
        return value.trim() === '' ? { border: '2px solid red' } : {};
    };

    const combinedStyle = (basicStyle, value) => {
        return { ...basicStyle, ...requiredFieldStyle(value) };
    };

    return (
        <div>
            <div className="submit-title">
                <h2>Submit Your Story</h2>
                <div className={"submit-disclaim"}>
                <p>By submitting this form, you are consenting to the possibility that the presenters may share your story on the podcast. It's important to recognize that the presenters will remain unaware of whether the story is based on reality or fiction until they finish reading it. Our team will sift through and condense the stories for the presenters, ensuring that they remain unaware of the story's authenticity. Please refrain from using actual names in your stories if those individuals would prefer not to be linked to the podcast.</p>
                <p>If you are selecting Fact, that doesn't necessarily mean it is provable. It just means that you or the person that it happened to believe that it is true. </p>
                <p>Fiction would be a story that is made up, Folk Lore, Creepy pasta, etc. This is where you get to be creative to try and trick the hosts.</p>
                </div>
            </div>
            {userEmail ? (
                <div className="input-container">
                    <label htmlFor="alias">What should we call you?</label>
                    <input type="text" id="alias" value={alias} onChange={(e) => setAlias(e.target.value)} style={combinedStyle(inputStyle, alias)} />

                    <label htmlFor="pronoun">What pronouns should we use?</label>
                    <input type="text" id="pronoun" value={pronoun} onChange={(e) => setPronoun(e.target.value)} style={combinedStyle(inputStyle, pronoun)} />

                    <label htmlFor="who">Who did this happen to?</label>
                    <select id="who" value={who} onChange={(e) => setWho(e.target.value)} style={combinedStyle(inputStyle, who)}>
                        <option value=""></option>
                        <option value="It happened to Me">It happened to Me</option>
                        <option value="It happened to someone else.">It happened to someone else.</option>
                    </select>

                    <label htmlFor="subject">What is the subject of the story?</label>
                    <select id="subject" value={subject} onChange={(e) => setSubject(e.target.value)} style={combinedStyle(inputStyle, subject)}>
                        <option value=""></option>
                        <option value="Haunting / Paranormal">Haunting / Paranormal</option>
                        <option value="Aliens / Encounters / Otherworldly">Aliens / Encounters / Otherworldly</option>
                        <option value="Intense Situation">Intense Situation</option>
                        <option value="Other">Other</option>
                    </select>

                    <label htmlFor="authenticity">Is the story Fact or Fiction?   </label>
                    <select id="authenticity" value={authenticity} onChange={(e) => setAuthenticity(e.target.value)} style={combinedStyle(inputStyle, authenticity)}>
                        <option value=""></option>
                        <option value="Fact">Fact</option>
                        <option value="Fiction">Fiction</option>
                    </select>

                    <label htmlFor="story">Please tell the story:</label>
                    <textarea id="story" value={story} onChange={handleStoryChange} style={combinedStyle({ ...inputStyle, height: '200px' }, story)} />

                    <button className="submit-button" onClick={handleSubmit} disabled={isLoading}>
                        {isLoading ? (
                            <>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </>
                        ) : (
                            'Submit'
                        )}
                    </button>
                </div>
            ) : (
                <div className={"submit-login"}>
                    <p>If you would like to submit a story please login to your account.</p>
                    <p>If you do not have an account, click the login button and signup for a new account.</p>
                    <div className="edit-button-container">
                        <button className="edit-button" onClick={() => {
                            authorize()
                        }}>Login</button>
                    </div>
                </div>
            )}
        </div>
    );
};


export default SubmitYourStory;
