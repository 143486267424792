import React from 'react';
import {useNavigate} from "react-router-dom";

const Successful = () => {
    const navigate = useNavigate();

    const goHome = () => {
        navigate('/Home');
    };

    return (
        <div className={"not-found"}>
            <h2>Submission Successful!</h2>
            <br></br>
            <p>Your story has been submitted to our hosts. Thank you!</p>
            <p>You should be able to see that story in the Listener Portal.</p>
            <br></br>
            <br></br>
            <button className="login-button" onClick={goHome}>Go Home</button>
        </div>
    );
};

export default Successful;