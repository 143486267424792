import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { trySilentLogin } from '../auth/AuthUtils';
import { apiEndpoint } from '../config';

const AdminStory = () => {
    const [userEmail, setUserEmail] = useState(null);
    const [selectedStory, setSelectedStory] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [revealAuthenticity, setRevealAuthenticity] = useState(false);
    const [guessList, setGuessList] = useState([{ host: '', guess: '' }]);
    const [showGuessButton, setShowGuessButton] = useState(true);
    const [showInputsAndSubmitButton, setShowInputsAndSubmitButton] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        trySilentLogin(setUserEmail);
        const storedStory = localStorage.getItem('selectedStory');
        if (storedStory) {
            setSelectedStory(JSON.parse(storedStory));
        }
    }, []);

    const updateStoryWithRetry = async (guessItem, retries = 3) => {
        const idToken = localStorage.getItem('id_token');
        while (retries > 0) {
            try {
                trySilentLogin(setUserEmail);
                const response = await axios({
                    url: `${apiEndpoint}adminStoryUpdate`,
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${idToken}`,
                    },
                    data: {
                        storyID: selectedStory.storyID,
                        answer: guessItem.guess,
                        host: guessItem.host,
                    },
                });
                if (response.status === 200) {
                    return true;
                }
            } catch (error) {
                console.error('Failed to update story', error);
                retries -= 1;
            }
        }
        return false;
    };

    const handleSubmit = async () => {
        setShowInputsAndSubmitButton(false); // Set to false to hide the inputs and submit button
        setIsLoading(true);
        for (const guessItem of guessList) {
            const success = await updateStoryWithRetry(guessItem);
            if (success) {
                setRevealAuthenticity(true);
            } else {
                console.error('Failed to update all stories after multiple retries');
            }
        }
        setIsLoading(false);
    };

    const addGuessField = () => {
        setGuessList([...guessList, { host: '', guess: '' }]);
    };

    const removeGuessField = (indexToRemove) => {
        setGuessList(prevGuessList => prevGuessList.filter((_, index) => index !== indexToRemove));
    };

    const handleChange = (index, field, value) => {
        const newGuessList = [...guessList];
        newGuessList[index][field] = value;
        setGuessList(newGuessList);
    };

    const clearAndGoBack = () => {
        localStorage.removeItem('selectedStory');
        window.location.href = '/admin';
    };

    if (!selectedStory) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>Admin Story Page</h1>
            <div className={"story-details-admin-read"}>
                <h2>Story Details:</h2>
                <ul>
                    <li><strong>Submitted Date:</strong> {selectedStory.submittedDate}</li>
                    <li><strong>Subject:</strong> {selectedStory.subject}</li>
                    <li><strong>Alias:</strong> {selectedStory.alias}</li>
                    <li><strong>Pronoun:</strong> {selectedStory.pronoun}</li>
                    <li><strong>Who did this happen to:</strong> {selectedStory.who}</li>
                    <li><strong>Story:</strong> {selectedStory.story}</li>
                </ul>
            </div>
            {showGuessButton && !revealAuthenticity && (
                <div className={"admin-read-guess-button-container"}>
                    <button className={"admin-read-guess-button"} onClick={() => { setShowForm(true); setShowGuessButton(false); }}>Guess</button>
                </div>
            )}
            {showForm && showInputsAndSubmitButton && (
                <div>
                    {guessList.map((guessItem, index) => (
                        <div className={"input-container-admin-read"} key={index}>
                            <label>
                                Name:
                                {index < 2 ? (
                                    <select required value={guessItem.host} onChange={(e) => handleChange(index, 'host', e.target.value)}>
                                        <option value="">Select Name</option>
                                        <option value="Ashley">Ashley</option>
                                        <option value="Chyna">Chyna</option>
                                    </select>
                                ) : (
                                    <input type="text" required value={guessItem.host} onChange={(e) => handleChange(index, 'host', e.target.value)} />
                                )}
                            </label>
                            <label>
                                Guess:
                                <select required value={guessItem.guess} onChange={(e) => handleChange(index, 'guess', e.target.value)}>
                                    <option value="">Select Guess</option>
                                    <option value="Fact">Fact</option>
                                    <option value="Fiction">Fiction</option>
                                </select>
                            </label>
                            <button onClick={() => removeGuessField(index)}>Remove</button>
                        </div>
                    ))}
                    <div className={"button-container-admin-read"}>
                        <button className={"button-admin-read"} onClick={addGuessField}>Add Another</button>
                        <button className={"button-admin-read"} onClick={handleSubmit}>Submit Answers</button>
                    </div>
                </div>
            )}
            {isLoading && (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}

            {revealAuthenticity && (
                <div className={"reveal-text"}>
                    <p>The Answer to this story is:</p>
                </div>
            )}
            {revealAuthenticity && (
                <h1 style={{ textAlign: 'center', fontSize: '3rem' }}>{selectedStory.authenticity}</h1>
            )}
            {revealAuthenticity && (
                <div className={"admin-read-guess-button-container"}>
                    <button className={"admin-read-guess-button"} onClick={clearAndGoBack}>Go back to Admin Page</button>
                </div>
            )}
        </div>
    );
};

export default AdminStory;

