import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { trySilentLogin } from '../auth/AuthUtils';
import { apiEndpoint } from '../config';
import axios from "axios";

const Admin = () => {
    const [stories, setStories] = useState([]);
    const navigate = useNavigate();

    const selectStory = (story) => {
        localStorage.setItem('selectedStory', JSON.stringify(story));
        navigate('/AdminStory');
    };

    const fetchData = async () => {
        const idToken = localStorage.getItem('id_token');
        try {
            const userResponse = await axios({
                url: `${apiEndpoint}adminGetStories`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
            });
            setStories(userResponse.data.stories);
        } catch (error) {
            console.error("An error occurred while fetching data. Attempting to refresh token and try again.", error);
            trySilentLogin(
                () => {},
                () => {},
                // Success callback
                async () => {
                    console.log("Token refreshed. Trying to fetch data again.");
                    await fetchData();  // Try fetching again
                },
                // Fail callback
                () => {
                    console.error("Unable to refresh token. Cannot fetch data.");
                }
            );
        }
    };

    useEffect(() => {
        const setUserEmail = (email) => {};

        const setGroupId = (groupId) => {
            localStorage.setItem('groups', JSON.stringify(groupId));
        };

        trySilentLogin(setUserEmail, setGroupId, () => {}, () => {});

        const groups = localStorage.getItem('groups');
        const parsedGroups = JSON.parse(groups);

        if (!parsedGroups || !parsedGroups.includes('a3a39454-4f83-4dcc-8e35-ed70ca94198d')) {
            navigate('/Home');
        }

        fetchData();
    }, []);

    return (
        <div>
            <h1>Admin Page</h1>
            <table className="table-bordered">
                <thead>
                <tr>
                    <th>Submitted Date</th>
                    <th>Subject</th>
                    <th>Alias</th>
                    <th>Pronoun</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {stories.map((story, index) => (
                    <tr key={index}>
                        <td>{story.submittedDate}</td>
                        <td>{story.subject}</td>
                        <td>{story.alias}</td>
                        <td>{story.pronoun}</td>
                        <td>
                            <button onClick={() => selectStory(story)}>Select</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default Admin;
