import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { trySilentLogin } from '../auth/AuthUtils';
import { apiEndpoint } from "../config";
import { Link } from 'react-router-dom';

const ListenerPortal = () => {
    const [stories, setStories] = useState([]);
    const [userInfo, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userEmail, setUserEmail] = useState(null);

    useEffect(() => {
        trySilentLogin(setUserEmail);
    }, []);

    useEffect(() => {
        if (!userEmail) return;

        const fetchData = async () => {
            const idToken = localStorage.getItem('id_token');
            try {
                const userResponse = await axios({
                    url: `${apiEndpoint}userDetailGet`,
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${idToken}`,
                    },
                });

                setUserInfo(userResponse.data);

                const storyResponse = await axios({
                    url: `${apiEndpoint}userStoryGet`,
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${idToken}`
                    }
                });

                const sortedStories = storyResponse.data.stories.sort((a, b) => {
                    return new Date(b.submittedDate) - new Date(a.submittedDate);
                });

                setStories(sortedStories);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [userEmail]);

    return (
        <div>
            <div className={"listener-portal-header"}>
                <h1>Listener Portal</h1>
            </div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div>
                    <h2 className="user-info">User Information</h2>
                    <div className="user-info">
                        {userInfo ? (
                            <div>
                                <p>Email: {userInfo['userEmail']}</p>
                                <p>Name: {userInfo['userName']}</p>
                                <p>Alias: {userInfo['userAlias']}</p>
                                <p>Phone Number: {userInfo['userPhone']}</p>
                                <p>Pronoun: {userInfo['userPronoun']}</p>
                            </div>
                        ) : (
                            <p>No user information available.</p>
                        )}
                    </div>
                    <div className="edit-button-container">
                        <Link to="/EditUser">
                            <button className="edit-button">Edit User Information</button>
                        </Link>
                    </div>
                    <div className={"header-listenerportal"} >
                        <h2>Stories</h2>
                    </div>
                    <table className="table-bordered">
                        <thead>
                        <tr>
                            <th>Submitted Date</th>
                            <th>Details</th>
                        </tr>
                        </thead>
                        <tbody>
                        {stories.map((story) => {
                            const localTime = new Date(story.submittedDate).toLocaleString();
                            return (
                                <tr key={story.storyID}>
                                    <td>{localTime}</td>
                                    <td>
                                        <strong>Alias:</strong> {story.alias}<br />
                                        <strong>Subject:</strong> {story.subject}<br />
                                        <strong>Who is this about:</strong> {story.who}<br />
                                        <strong>Story:</strong> {story.story}
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default ListenerPortal;

